import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { useSelectedRegatta } from "./RegattaState";
import { watchRegattaData } from "./UseResults";

export const UrlTracker = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [, setSelectedRegatta] = useSelectedRegatta();
  const pathParts = location.pathname.split("/");
  let regattaId = "";
  let action = "";
  if (pathParts.length >= 3) {
    if (
      [
        "goto",
        "qrcodes",
        "schedule",
        "heatsheet",
        "live-stream",
        "live",
        "packet",
        "points",
        "regatta",
        "tracking",
        "edit",
        "log",
        "manage",
        "payment",
        "results-edit",
      ].includes(pathParts[1])
    ) {
      regattaId = (pathParts[2] || "").replace(/\./, "");
    }
    action = pathParts[1];
  }
  useEffect(() => {
    setSelectedRegatta(regattaId);
    if (regattaId) {
      watchRegattaData(regattaId);
    }
    if (action === "goto") {
      navigate(`/appstore`, { replace: true });
    }
  }, [action, regattaId, setSelectedRegatta, navigate]);
  return <></>;
};
