import { Invoice } from "./Invoice";
import { useSelectedRegatta } from "./shared/RegattaState";
import { useRegattaInfo, useRegattaTitle } from "./shared/UseResults";
import Util from "./shared/Util";

const vendor = {
  name: "CrewTimer Regatta Timing",
  email: "info@crewtimer.com",
  street: "3227 139th Ave SE",
  city: "Snohomish",
  state: "WA",
  zip: "98290",
};

const purchaser = {
  name: "John Smith",
  email: "",
  street: "",
  city: "",
  state: "",
  zip: "",
};

const singleItem = {
  description: "Regatta Timing Fee per entry",
  quantity: 343,
  priceEach: 0.5,
};
export const Payment = () => {
  const [regattaTitle] = useRegattaTitle();
  const [regattaId] = useSelectedRegatta();
  const [regattaInfo] = useRegattaInfo();
  const user = Util.getUser();
  console.log(`regatta is ${regattaId} title is ${regattaTitle}`);
  const numEntries = regattaInfo?.NumEntries || 0;
  const date = regattaInfo?.Date || "2025-01-01";
  singleItem.quantity = numEntries;
  purchaser.name = user?.displayName || "";
  purchaser.email = user?.email || "";
  return (
    <Invoice
      logoUrl="https://dev.crewtimer.com/static/media/logo.4ccd3d01842dbd5c1245f94e17b2f8a8.svg" // Replace with your own logo URL
      vendor={vendor}
      purchaser={purchaser}
      invoiceDate={date}
      invoiceNumber={regattaId}
      invoiceProject={regattaTitle}
      item={singleItem}
    />
  );
};
