import {
  PayPalButtons,
  PayPalButtonsComponentProps,
  PayPalScriptProvider,
} from "@paypal/react-paypal-js";
import { useState } from "react";

const payPalOptions = {
  clientId:
    "AbgmhhRzZvZXqjx9efuICxWfETws7f9f25h_9Sw-41c9RiA9Q04ofqfBs9mPMBq2tlDfoTt8hDZcocBG",
  currency: "USD",
  intent: "capture",
  components: "buttons",
  disableFunding: "paylater",
};

export const PayPalPayment = () => {
  const [resultsError, setResultsError] = useState("");
  const createOrder = async () => {
    try {
      setResultsError("");
      const response = await fetch(
        "https://dev.crewtimer.com/create-paypal-order",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            cart: [
              { id: "YOUR_PRODUCT_ID", quantity: "YOUR_PRODUCT_QUANTITY" },
            ],
          }),
        }
      );

      const orderData = (await response.json())?.order;
      console.log("order data is ", JSON.stringify(orderData));

      if (!orderData.id) {
        const errorDetail = orderData.details[0];
        const errorMessage = errorDetail
          ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
          : "Unexpected error occurred, please try again.";

        throw new Error(errorMessage);
      }

      console.log(`Created order with id: ${orderData.id}`);
      return orderData.id;
    } catch (error) {
      const msg = error instanceof Error ? error.message : String(error);
      console.error(msg);
      setResultsError(msg);
      throw error;
    }
  };

  const onApprove = (data, actions) => {
    return actions.order.capture().then((details) => {
      const name = details.payer.name.given_name;
      alert(`Transaction completed by ${name}`);
    });
  };

  const styles: PayPalButtonsComponentProps["style"] = {
    label: "pay",
  };
  return (
    <PayPalScriptProvider options={payPalOptions}>
      <PayPalButtons
        createOrder={createOrder}
        onApprove={onApprove}
        style={styles}
      />
      {resultsError && <div>{resultsError}</div>}
    </PayPalScriptProvider>
  );
};
